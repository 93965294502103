export const ENGLISH = {
    analytics: {
        country: 'Country',
        previousPeriod: 'than previous period',
        users: 'Users'
    },
    header: {
        accountSetting: 'Account Setting',
        accountSettings: 'Account settings',
        analytics: 'Analytics',
        companyCards: 'Company Cards',
        myCards: 'My Cards',
        profilePage: 'Profile Page',
        walletCard: 'Wallet Card',
        settings: 'Settings',
        signedInAs: 'Signed in as',
        signOut: 'Sign out',
        switchToBusinessAccount: 'Switch to business account',
        visitWebsite: 'Visit website'
    },
    common: {
        about: 'About',
        activated: 'Activated',
        addMember: 'Add member',
        appearance: 'Appearance',
        allCards: 'All cards',
        assign: 'Assign',
        assignCard: 'Assign card',
        assignCardTo: 'Assign card to',
        cancel: 'Cancel',
        cardAssignedTo: 'Card assigned to',
        cardViews: 'Card views',
        changeCardOwner: 'Change card owner',
        changePassword: 'Change Password',
        changesSaved: 'Saved',
        close: 'Close',
        companyCards: 'Company cards',
        companyLogo: 'Company Logo',
        companyMembers: 'Company Members',
        contact: 'Contact',
        contacts: 'Contacts',
        contactSaved: 'Contact saved',
        copy: 'Copy',
        copied: 'Copied!',
        currentPassword: 'Current Password',
        dataAndPrivacy: 'Data and Privacy',
        deleteCard: 'Delete card',
        deletePermanently: 'Delete permanently',
        dismiss: 'Dismiss',
        download: 'Download',
        edit: 'Edit',
        editCard: 'Edit card',
        email: 'Email',
        emailDash: 'E-mail',
        emailAddress: 'Email address',
        enabled: 'Enabled',
        facebook: 'Facebook',
        general: 'General',
        inactive: 'Inactive',
        instagram: 'Instagram',
        inviteAs: 'Invite as',
        inviteNewMember: 'Invite new member',
        language: 'Language',
        leave: 'Leave',
        links: 'Links',
        linkedin: 'Linkedin',
        managedBy: 'Managed by',
        member: 'Member',
        message: 'Message',
        more: 'More',
        moveToCompany: 'Move to company cards',
        myCards: 'My cards',
        name: 'Name',
        newPassword: 'New Password',
        noFileChosen: 'No file chosen',
        notActivated: 'Not activated',
        notifications: 'Notifications',
        others: 'Others',
        password: 'Password',
        pending: 'Pending',
        phone: 'Phone',
        previewCard: 'Preview card',
        pleaseSelectOption : 'Please select an option',
        qrCode: 'QR code',
        refreshPage : 'Please refresh the page to continue',
        reload: 'Reload',
        remove: 'Remove',
        role: 'Role',
        save: 'Save',
        saveChanges: 'Save changes',
        saveContact: 'Save Contact',
        search: 'Search',
        selectOption: 'Select option',
        selectPlatform: 'Select platform',
        send: 'Send',
        sent: 'Sent',
        sessionExpired: 'Session Expired',
        setInactive: 'Set inactive',
        share: 'Share',
        shareCard: 'Share card',
        stay: 'Stay',
        subject: 'Subject',
        subjectLine: 'Subject line',
        support: 'Support',
        today: 'Today',
        tiktok: 'Tiktok',
        title: 'Title',
        to: 'to',
        uniqueUsers: 'Unique users',
        unnamed: 'Unnamed',
        unsavedChanges: 'Unsaved changes',
        unsavedChangesText: 'It looks like you have been editing something. If you leave before saving, your changes will be lost.',
        usersByCountries: 'Users by Country',
        yourAccount: 'Your Account',
        website: 'Website',
        whatsapp: 'WhatsApp',
        x: 'X',
        yourBusinessCard: 'Your business card'
    },
    contact: {
        description1: 'Do you have any questions or need advice?',
        description2: ' We\'re happy to help you!',
        hello: 'Hello!',
        liveChat: 'Live Chat'
    },
    modal: {
        addUserRoleText: 'Are you sure you want to assign the {{role}} role to {{username}}?',
        areYouSure: 'Are you sure?',
        changeCardOwnerDesc: 'If you change the card owner, you will no longer be able to manage or view this card profile',
        deleteAccountConfirm: 'Yes, delete my account',
        deleteAccountText: 'Are you absolutely sure that you want to delete your iCards account? Please note that there is no option to restore the account or its data once it\'s deleted.',
        deleteText: 'This action cannot be undone, if you delete this card it is gone forever. Any link or QR code associated with this card will no longer work.',
        enterCompanyName: 'Enter company name',
        hiThere: 'Hi there 👋',
        howCanIhelpYou: 'How can I help you',
        iCardsTeam : 'iCards Team',
        iCardsTeamSubTitle : 'Typically replies within a day',
        lostPhysicalCard: 'Lost your physical card?',
        lostPhysicalCardText: 'If your card was lost or stollen, for your data protection you have option to set profile used for this cards as inactive. If your card was damaged you can proceed to order replacement card with the link to the same profile, and all your information will remain unchanged.',
        orderNewCardText: 'Order replacement card with the link to the same profile, and all your information will remain unchanged.',
        setInactiveText : 'This option will deactivate link to your profile and you can proceed with to order replacement card with the new profile.',
        startChat : 'Start Chat',
    },
    myCards : {
        clickBtnToCreateCard: 'Click the button below to create your first digital business card',
        orderNewCard: 'Order new card',
        setInactiveText: 'Set inactive if this card was lost, stollen or no longer in use. This option will deactivate link to your profile.',
        setInactiveText2: 'Set this card as inactive if you do not want it to be visible.',
        youDontHaveCards: 'You don\'t have any cards'
    },
    labels: {
        buttonBackgroundColor: 'Button Background Color',
        buttonTextColor: 'Button Text Color',
        cardBackgroundColor: 'Card Background Color',
        cardTextColor: 'Card Text Color',
        city: 'City',
        company: 'Company',
        companyBgColor: 'Company Background Color',
        companyName: 'Company name',
        companyTextColor: 'Company Text Color',
        country: 'Country',
        cover: 'Cover',
        coverBackgroundColor: 'Cover Background Color',
        createCompany: 'Create company',
        createNewCompany: 'Create a new company',
        department: 'Department',
        filterBy: 'Filter by',
        fullname: 'Full Name',
        iconBackgroundColor: 'Icon Background Color',
        iconsColor: 'Icons Color',
        pendingUserHasNotAcceptYet: 'Pending: waiting for the user to activate the account',
        position: 'Position',
        streetAdress: 'Street Address',
        zipPostal: 'Zip / Postal'
    },
    messages: {
        companyWasSuccessfullyCreated: 'The company was successfully created',
        internalServerError: 'Internal server error',
        noIcardsFound: 'No iCards found',
        passwordHasBeenChanged: 'The password has been changed.',
        profileWasSuccessfullyAssigned: 'Profile was successfully assigned.'
    },
    profile: {
        colors: {
            custom: 'Custom',
            default: 'Default',
            green: 'Green',
            purple: 'Purple',
            red: 'Red',
            yellow: 'Yellow'
        },
        error: {
            fileTooLarge: 'File is too large. Please upload a file smaller than 5MB.',
            invalidEmailAddress: 'Invalid email address',
            invalidFileType: 'Invalid file type. Please upload a PNG or JPEG file.',
            specialCharacters: 'You should not use special characters'
        },
        general:{
            changeCover: 'Change cover',
            changePic: 'Change picture',
            dragAndDrop: 'or drag and drop',
            noFileChosen: 'No file chosen',
            pngJpgGif : 'PNG, JPG, GIF up to 10MB',
            uploadFile: 'Upload file',
            uploadPic: 'Upload picture'
        },
        links: {
            addLink: 'Add link'
        },
        contact: {
            addField: 'Add field'
        },
        noContentAdded: 'has not added any content yet',
        poweredBy: 'Powered by :'
    },
    selector: {
        last7days: 'Last 7 days',
        last14days: 'Last 14 days',
        last30days: 'Last 30 days',
        totalViews: 'Total views'
    },
    settings: {
        deleteYourAccount: 'Delete your account',
        permanentlyDeleteYourAccount: 'Permanently delete your account',
        privacyPolicy: 'Privacy policy',
        termsOfService: 'Terms of Service',
        termsAndConditions: 'Terms and Conditions',
        twofaEnableBtn: 'Enable 2FA',
        twofaDisableBtn: 'Disable 2FA',
        twofaDesc: 'Two-Factor Authentication protects your account by adding an extra security step when you log in.',
        twofaTitle: 'Two-factor authentication (2FA)'
    },
    activate: {
        assignProfile: 'Do you want to activate your new iCard {{uuid}} and link it to this account?',
        failedToActivateProfile: 'Failed to activate your iCard',
        profileActivation: 'Activate your new iCard',
        welcomeMessage1: 'Welcome to iCards',
        welcomeMessage2: 'Your iCard is not yet activated. Please click the button below to activate it',
        welcomeMessage3: 'If you already have account you will need to log in, otherwise you will need to register.',
        welcomeActivateButton: 'Activate your iCard',
        yesActivate: 'Yes, activate!',
        yourProfileIsActivated: 'Your iCard has been successfully activated!'
    }

};